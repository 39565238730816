<template>
  <div>
    <div v-if="state == 'input'" class="d-flex flex-column flex-root">
      <div>
        <div class="container-fluid" style="margin-bottom: 20px">
          <div class="row background-red p-2">
            <div class="container-fluid">
              <div class="row justify-content-between">
                <div class="col-md-4 text-center mt-auto mb-auto">
                  <img src="/img/confirmation/logo-kgmedia.png" alt="" />
                </div>
                <div class="col-md-4 text-center mt-auto mb-auto">
                  <span style="font-size: 31px" class="text-white"
                    >Konfirmasi Kehadiran</span
                  >
                </div>
                <div class="col-md-4 text-center mt-auto mb-auto">
                  <img src="/img/confirmation/logo-ocbc.png" alt="" />
                </div>
              </div>
            </div>
          </div>

          <div class="row justify-content-center p-2" style="margin-top: 10px">
            <img src="/img/confirmation/Logo-Baru.png" alt="" />
          </div>
        </div>

        <div class="row justify-content-center" v-if="state == 'input'">
          <div class="col-8">
            <div class="card">
              <div
                class="card-body py-2 back-trans"
                style="background-color: #a21322 !important"
              >
                <span class="font-weight-bolder text-white">{{
                  form.email
                }}</span>
                <div class="row">
                  <div class="col-sm-12 col-md-6 pr-1">
                    <b-form-group id="input-group-password">
                      <label
                        for="input-password"
                        class="text-white mb-0 mt-1 font-weight-bolder"
                      >
                        Silahkan buat "Password" anda dibawah ini
                      </label>
                      <b-input-group>
                        <b-form-input
                          id="input-password"
                          v-model="form.password"
                          placeholder="Password"
                          :type="passwordIsVisible"
                        ></b-form-input>
                        <template #append>
                          <b-input-group-text
                            @click="
                              passwordIsVisible == 'password'
                                ? (passwordIsVisible = 'text')
                                : (passwordIsVisible = 'password')
                            "
                          >
                            <div v-if="passwordIsVisible == 'password'">
                              <b-icon-eye-slash></b-icon-eye-slash>
                            </div>
                            <div v-if="passwordIsVisible == 'text'">
                              <b-icon-eye></b-icon-eye>
                            </div>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <small class="text-white">{{ error.password }}</small>
                      <span
                        ><i class="text-white font-size-sm font-weight-light"
                          >*password ini digunakan untuk login kedalam
                          platform</i
                        ></span
                      >
                    </b-form-group>
                  </div>
                  <div class="col-sm-12 col-md-6 pl-1 mt-1">
                    <b-form-group id="input-group-password-confirmation">
                      <label
                        for="input-password-confirmation"
                        class="text-white mb-0 mt-1 font-weight-bolder"
                      >
                      </label>
                      <b-input-group>
                        <b-form-input
                          id="input-password-confirmation"
                          v-model="form.password_confirmation"
                          placeholder="Konfirmasi Password"
                          :type="passwordConfirmationIsVisible"
                        ></b-form-input>
                        <template #append>
                          <b-input-group-text
                            @click="
                              passwordConfirmationIsVisible == 'password'
                                ? (passwordConfirmationIsVisible = 'text')
                                : (passwordConfirmationIsVisible = 'password')
                            "
                          >
                            <div
                              v-if="passwordConfirmationIsVisible == 'password'"
                            >
                              <b-icon-eye-slash></b-icon-eye-slash>
                            </div>
                            <div v-if="passwordConfirmationIsVisible == 'text'">
                              <b-icon-eye></b-icon-eye>
                            </div>
                          </b-input-group-text>
                        </template>
                      </b-input-group>
                      <small class="text-white">{{
                        error.password_confirmation
                      }}</small>
                    </b-form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- begin:: Content -->
        <div class="row justify-content-center" v-if="state == 'input'">
          <div class="col-8">
            <div
              class="card bgi-size-cover bgi-position-center bgi-no-repeat"
              :style="{
                backgroundImage: `url(${backgroundImage})`,
              }"
            >
              <div class="card-body back-trans">
                <!-- <div class="row mt-4">
                <div class="col-12">
                  <b-form-group
                    id="input-group-password"
                    label="Password:"
                    label-for="input-password"
                  >
                    <b-input-group>
                      <b-form-input
                        id="input-password"
                        v-model="form.password"
                        placeholder="Password"
                        :type="passwordIsVisible"
                      ></b-form-input>
                      <template #append>
                        <b-input-group-text
                          @click="
                            passwordIsVisible == 'password'
                              ? (passwordIsVisible = 'text')
                              : (passwordIsVisible = 'password')
                          "
                        >
                          <div v-if="passwordIsVisible == 'password'">
                            <b-icon-eye-slash></b-icon-eye-slash>
                          </div>
                          <div v-if="passwordIsVisible == 'text'">
                            <b-icon-eye></b-icon-eye>
                          </div>
                        </b-input-group-text>
                      </template>
                    </b-input-group>
                    <small class="text-danger">{{ error.password }}</small>
                  </b-form-group>
                  <b-button
                    type="submit"
                    variant="primary"
                    @click="formOnsubmit"
                    >Konfirmasi</b-button
                  >
                 
                </div>
              </div> -->

                <form class="form" @submit.stop.prevent="formOnsubmit()">
                  <!--begin: Wizard Step 1-->
                  <div
                    class="pb-5"
                    data-wizard-type="step-content"
                    data-wizard-state="current"
                  >
                    <h5
                      class="
                        font-weight-bolder
                        text-dark
                        font-size-h6 font-size-h3-lg
                      "
                    >
                      <span>
                        <!-- {{ title }} -->
                      </span>
                    </h5>

                    <template
                      v-if="
                        this.form.member_role_id != 9 &&
                        this.form.member_role_id != 6
                      "
                    >
                      <b-form-group
                        id="input-group-bussines-name"
                        label="Nama UMKM:"
                        label-for="input-bussines-name"
                      >
                        <b-form-input
                          id="input-bussines-name"
                          v-model="form.business_name"
                          placeholder="Nama UMKM"
                        ></b-form-input>
                        <small class="text-danger">{{
                          error.business_name
                        }}</small>
                      </b-form-group>

                      <b-form-group id="input-group-address">
                        <label for="input-address"
                          >Informasi terkait UMKM:
                        </label>
                        <b-form-textarea
                          id="input-address"
                          v-model="form.description"
                          placeholder="Penjelasan singkat mengenai UMKM"
                          rows="4"
                          max-rows="8"
                        ></b-form-textarea>
                        <small class="text-danger">{{
                          error.description
                        }}</small>
                      </b-form-group>

                      <b-form-group id="input-group-address">
                        <label for="input-address">Alamat UMKM: </label>
                        <b-form-textarea
                          id="input-address"
                          v-model="form.business_address"
                          placeholder="Alamat Lengkap UMKM"
                          rows="4"
                          max-rows="8"
                        ></b-form-textarea>
                        <small class="text-danger">{{
                          error.business_address
                        }}</small>
                      </b-form-group>

                      <b-form-group
                        id="input-group-youtube-link"
                        label="Peran UMKM:"
                        label-for="input-youtube-link"
                      >
                        <treeselect
                          v-model="form.member_role_id"
                          :multiple="false"
                          :options="roles"
                          required
                          placeholder="Pilih Peran UMKM"
                        />
                        <small class="text-danger">{{
                          error.member_role_id
                        }}</small>
                      </b-form-group>

                      <b-form-group id="input-group-industry">
                        <label for="input-group-industry"
                          >Industri UMKM:
                        </label>
                        <treeselect
                          v-model="form.industry_type"
                          :multiple="false"
                          :options="industryType"
                          required
                          placeholder="Pilih Industri"
                        />
                        <small class="text-danger">{{
                          error.industry_type
                        }}</small>
                      </b-form-group>

                      <div class="row">
                        <div class="col">
                          <b-form-group id="input-group-bussines-city">
                            <label for="input-business-city"
                              >Kota Usaha:
                            </label>
                            <b-form-input
                              id="input-bussines-city"
                              v-model="form.business_city"
                              placeholder="Kota Usaha"
                            ></b-form-input>
                            <small class="text-danger">{{
                              error.business_city
                            }}</small>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <b-form-group id="input-group-postal-code">
                            <label for="input-postal-code">Kode Pos: </label>
                            <b-form-input
                              id="input-postal-code"
                              v-model="form.postal_code"
                              placeholder="Kode Pos"
                            ></b-form-input>
                            <small class="text-danger">{{
                              error.postal_code
                            }}</small>
                          </b-form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col">
                          <b-form-group id="input-group-age">
                            <label for="input-group-age">Lama Usaha: </label>
                            <b-input-group>
                              <b-form-input
                                id="input-age"
                                v-model="form.business_estabilished_age"
                                placeholder="Lama Usaha"
                              ></b-form-input>
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon1"
                                  >Tahun</span
                                >
                              </div>
                            </b-input-group>
                            <small class="text-danger">{{
                              error.business_estabilished_age
                            }}</small>
                          </b-form-group>
                        </div>
                        <div class="col">
                          <b-form-group id="input-group-monthly-omset">
                            <label for="input-monthly-omset"
                              >Omset Usaha:
                            </label>
                            <b-input-group>
                              <b-form-input
                                id="input-monthly-omset"
                                v-model="formatedMonthlyOmset"
                                placeholder="Omset Usaha"
                              ></b-form-input>
                              <div class="input-group-append">
                                <span class="input-group-text" id="basic-addon1"
                                  >/Bulan</span
                                >
                              </div>
                            </b-input-group>
                            <small class="text-danger">{{
                              error.monthly_omset
                            }}</small>
                          </b-form-group>
                        </div>
                      </div>

                      <b-form-group id="input-group-legality">
                        <label for="input-group-legality"
                          >Jenis Badan Usaha:
                        </label>
                        <treeselect
                          v-model="form.business_legal_type"
                          :multiple="false"
                          :options="businessLegality"
                          required
                          placeholder="Pilih Badan Usaha"
                        />
                        <small class="text-danger">{{
                          error.business_legal_type
                        }}</small>
                      </b-form-group>
                    </template>

                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label
                            for="input-name"
                            v-if="this.form.member_role_id == 6"
                            >Foto Narasumber</label
                          >
                          <label
                            for="input-name"
                            v-else-if="this.form.member_role_id == 9"
                            >Foto Moderator</label
                          >
                          <label
                            for="input-name"
                            v-else-if="this.form.is_partner_display == 'Mitra'"
                            >Foto Mitra</label
                          >
                          <label
                            for="input-name"
                            v-else-if="
                              this.form.is_partner_display == 'Peserta'
                            "
                            >Foto Pemilik UMKM</label
                          >
                          <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                            <div
                              class="image-input image-input-outline"
                              id="kt_photo_url"
                            >
                              <div
                                class="
                                  image-input-wrapper
                                  image-input-wrapper-thumbnail
                                "
                              >
                                <img
                                  :src="photoCpt"
                                  alt=""
                                  width="100px"
                                  height="100px"
                                />
                              </div>
                              <label
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="change"
                                data-toggle="tooltip"
                                title=""
                                data-original-title="Change avatar"
                              >
                                <i class="fa fa-pen icon-sm text-muted"></i>
                                <input
                                  type="file"
                                  name="photo_url"
                                  accept=".png, .jpg, .jpeg"
                                  @change="onPhoto($event)"
                                />
                                <input type="hidden" name="photo_url_remove" />
                              </label>
                              <span
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="cancel"
                                data-toggle="tooltip"
                                title="Cancel avatar"
                              >
                                <i
                                  class="ki ki-bold-close icon-xs text-muted"
                                ></i>
                              </span>
                              <span
                                class="
                                  btn
                                  btn-xs
                                  btn-icon
                                  btn-circle
                                  btn-white
                                  btn-hover-text-primary
                                  btn-shadow
                                "
                                data-action="remove"
                                data-toggle="tooltip"
                                title="Remove avatar"
                                @click="onRemovePhoto()"
                              >
                                <i
                                  class="ki ki-bold-close icon-xs text-muted"
                                ></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-8">
                        <b-form-group id="input-group-name">
                          <label
                            for="input-name"
                            v-if="this.form.member_role_id == 6"
                            >Nama Narasumber</label
                          >
                          <label
                            for="input-name"
                            v-else-if="this.form.member_role_id == 9"
                            >Nama Moderator</label
                          >
                          <label
                            for="input-name"
                            v-else-if="(this.form.is_partner_display = 'Mitra')"
                            >Nama Mitra</label
                          >
                          <label
                            for="input-name"
                            v-else-if="
                              (this.form.is_partner_display = 'Peserta')
                            "
                            >Nama Pemilik UMKM</label
                          >
                          <b-form-input
                            id="input-name"
                            v-model="form.name"
                            placeholder="Nama Pemilik UMKM"
                          ></b-form-input>
                          <small class="text-danger">{{ error.name }}</small>
                        </b-form-group>
                        <b-form-group
                          id="input-group-gender"
                          label="Jenis Kelamin:"
                          label-for="input-gender"
                          v-if="
                            this.form.member_role_id != 9 &&
                            this.form.member_role_id != 6
                          "
                        >
                          <b-form-select
                            v-model="form.gender"
                            :options="options"
                          ></b-form-select>
                          <small class="text-danger">{{ error.gender }}</small>
                        </b-form-group>
                        <b-form-group
                          id="input-group-email"
                          label="Email:"
                          label-for="input-email"
                        >
                          <b-form-input
                            id="input-email"
                            v-model="form.email"
                            placeholder="Email"
                            readonly
                          ></b-form-input>
                          <small class="text-danger">{{ error.email }}</small>
                        </b-form-group>

                        <b-form-group
                          id="input-group-phone"
                          label="Telepon:"
                          label-for="input-phone"
                        >
                          <b-form-input
                            id="input-phone"
                            v-model="form.phone"
                            placeholder="Telepon"
                          ></b-form-input>
                          <small class="text-danger">{{ error.phone }}</small>
                        </b-form-group>
                      </div>
                    </div>

                    <template
                      v-if="
                        this.form.member_role_id != 9 &&
                        this.form.member_role_id != 6
                      "
                    >
                      <hr />
                      <div class="d-flex justify-content-between">
                        <h6>Produk Unggulan UMKM</h6>
                        <b-button
                          squared
                          style="background-color: #a21322; border: none"
                          class="text-white"
                          v-b-tooltip.hover
                          title="Tambah Produk"
                          @click="$bvModal.show('modal-form-product')"
                        >
                          <i class="fas fa-plus text-white"></i>
                          Produk
                        </b-button>
                      </div>
                      <div class="row mt-2">
                        <table class="table">
                          <tr>
                            <th>No</th>
                            <th>Gambar</th>
                            <th>Nama</th>
                            <th>Harga</th>
                            <th></th>
                          </tr>
                          <tr
                            v-for="(item, index) in productCollection"
                            :key="index"
                          >
                            <td>{{ index + 1 }}</td>
                            <td>
                              <div
                                class="
                                  d-flex
                                  justify-content-start
                                  align-items-center
                                  image-container
                                "
                              >
                                <div
                                  class="image-input-wrapper-product"
                                  style="position: relative"
                                >
                                  <img
                                    class="image"
                                    width="70px"
                                    height="70px"
                                    :src="item.image_display"
                                  />
                                </div>
                              </div>
                            </td>
                            <td>{{ item.name }}</td>
                            <td>{{ item.price }}</td>
                            <td>
                              <b-button
                                size="sm"
                                class="btn-circle btn-outline-success"
                                v-b-tooltip.hover
                                title="Edit"
                                @click="getProductByIndex(index)"
                                ><i class="fas fa-edit px-0"></i
                              ></b-button>
                              <b-button
                                size="sm"
                                class="btn-circle btn-outline-danger ml-2"
                                v-b-tooltip.hover
                                title="Hapus"
                                @click="deleteProduct(index)"
                                ><i class="fas fa-trash px-0"></i
                              ></b-button>
                            </td>
                          </tr>
                        </table>
                      </div>
                    </template>
                  </div>
                  <!--end: Wizard Step 1-->

                  <!--begin: Wizard Actions -->
                  <div class="d-flex justify-content-between border-top pt-10">
                    <div>
                      <b-button
                        type="submit"
                        style="background-color: #a21322; border: none"
                        class="text-white"
                        >Simpan</b-button
                      >
                      <!-- <b-button
                      type="button"
                      class="ml-2"
                      variant="default"
                      @click="$router.push('/members')"
                    >
                      Batal
                    </b-button> -->
                    </div>
                  </div>
                  <!--end: Wizard Actions -->
                </form>
              </div>
            </div>
          </div>
        </div>
        <!-- end:: Content -->

        <!-- Modal Form Detail -->
        <b-modal
          id="modal-form-product"
          size="xl"
          hide-footer
          no-close-on-backdrop
        >
          <template #modal-title> Form Produk Unggulan </template>
          <div class="row">
            <div class="col">
              <div class="form-group">
                <label class="col-xl-3 col-lg-8 col-form-label pl-0"
                  >Foto Pemilik UMKM</label
                >
                <div class="col-lg-9 col-xl-6 pl-0 pt-0">
                  <div
                    class="image-input image-input-outline"
                    id="kt_photo_url"
                  >
                    <div
                      class="image-input-wrapper image-input-wrapper-thumbnail"
                    >
                      <img
                        :src="productPhoto"
                        alt=""
                        width="100px"
                        height="100px"
                      />
                    </div>
                    <label
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="change"
                      data-toggle="tooltip"
                      title=""
                      data-original-title="Change avatar"
                    >
                      <i class="fa fa-pen icon-sm text-muted"></i>
                      <input
                        type="file"
                        ref="productImage"
                        name="product_image"
                        accept=".png, .jpg, .jpeg"
                        @change="onUploadProductPhoto($event)"
                      />
                      <input type="hidden" name="photo_url_remove" />
                    </label>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="cancel"
                      data-toggle="tooltip"
                      title="Cancel avatar"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                    <span
                      class="
                        btn
                        btn-xs
                        btn-icon
                        btn-circle
                        btn-white
                        btn-hover-text-primary
                        btn-shadow
                      "
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="onRemoveProductPhoto()"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-8">
              <b-form-group
                id="input-group-product-name"
                label="Nama Produk:"
                label-for="input-product-name"
              >
                <b-form-input
                  id="input-product-name"
                  v-model="formProduct.name"
                  placeholder="Nama Produk"
                ></b-form-input>
                <small class="text-danger">{{ errorProduct.name }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-product-price"
                label="Harga Produk:"
                label-for="input-product-price"
              >
                <b-form-input
                  id="input-product-price"
                  v-model="formatedProductPrice"
                  placeholder="Harga Produk"
                ></b-form-input>
                <small class="text-danger">{{ errorProduct.price }}</small>
              </b-form-group>

              <b-form-group
                id="input-group-address"

              >
                <label for="input-address">Deskripsi Produk: </label>
                <b-form-textarea
                  id="input-address"
                  v-model="formProduct.description"
                  placeholder="Deskripsi Produk"
                  rows="4"
                  max-rows="8"
                ></b-form-textarea>
                <small class="text-danger">{{ errorProduct.description }}</small>
              </b-form-group>
            </div>
          </div>

          <div class="row justify-content-end">
            <b-button
              class="mt-3 mr-2"
              @click="$bvModal.hide('modal-form-product')"
              >Batal</b-button
            >
            <b-button class="mt-3" variant="primary" @click="saveProduct"
              >Simpan</b-button
            >
          </div>
        </b-modal>
      </div>
    </div>
    <div v-if="state == 'success'" class="d-flex flex-column flex-root">
      <div class="container-fluid" style="margin-bottom: 20px">
        <div class="row background-red p-2">
          <div class="container-fluid">
            <div class="row justify-content-between">
              <div class="col-md-4 text-center mt-auto mb-auto">
                <img src="/img/confirmation/logo-kgmedia.png" alt="" />
              </div>
              <div class="col-md-4 text-center mt-auto mb-auto">
                <span style="font-size: 31px" class="text-white"
                  >Konfirmasi Kehadiran</span
                >
              </div>
              <div class="col-md-4 text-center mt-auto mb-auto">
                <img src="/img/confirmation/logo-ocbc.png" alt="" />
              </div>
            </div>
          </div>
        </div>

        <div
          class="row justify-content-center p-2 text-center"
          style="margin-top: 13px; height: 42px"
        >
          <span
            style="margin-top: 13px; font-size: 30px; color: #a21322"
            class="font-weight-bold d-block"
            >HI JAGOAN LOKAL,</span
          >
        </div>
        <div class="row justify-content-center p-2 text-center">
          <span
            style="font-size: 26px; color: #a21322"
            class="font-weight-bold d-block"
          >
            SELAMAT DATANG DI NETWORKING #ONPRENEURSHIP
          </span>
        </div>

        <div class="row justify-content-center p-2" style="margin-top: 30px">
          <img src="/img/confirmation/Logo-Baru.png" alt="" />
        </div>

        <div
          class="row justify-content-center p-2 text-center"
          style="margin-top: 30px"
        >
          <span
            style="font-size: 26px; color: #a21322; height: 17px"
            class="font-weight-bold d-block"
          >
            ANDA TELAH BERHASIL KONFIRMASI KEHADIRAN.
          </span>
        </div>
        <div class="row justify-content-center p-2 text-center">
          <span
            style="font-size: 26px; color: #a21322; height: 17px"
            class="font-weight-bold d-block"
          >
            MOHON CEK KEMBALI EMAIL ANDA,
          </span>
        </div>
        <div class="row justify-content-center p-2 text-center">
          <span
            style="font-size: 26px; color: #a21322; height: 17px"
            class="font-weight-bold d-block"
          >
            UNTUK DAPAT LOGIN KE NETWORKING #ONPRENEURSHIP.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";

export default {
  data() {
    return {
      // form: {
      //   password: "",
      //   token: "",
      //   pitching_event_id: "",
      // },
      error: {
        token: "",
        pitching_event_id: "",
        name: "",
        description: "",
        start_time: "",
        end_time: "",
        status_id: "",
        image_header: "",
        image_thumbail: "",
        password: "",
        password_confirmation: "",
      },
      passwordIsVisible: "password",
      passwordConfirmationIsVisible: "password",
      state: "input",

      form: {},
      dataLoaded: false,
      obj: {},

      //attachment
      attachmentData: {},
      attachment: [],
      file_name: [],

      formProduct: {
        price: "",
        name: "",
        description: "",
        image: "",
        image_display: "",
        image_default: "",
      },
      errorProduct: {
        price: "",
        image: "",
        name: "",
        description: "",
      },
      productCollection: [],
      fileCollection: [],
      productDeleted: [],
      productPurpose: "add",
      indexProduct: 0,
      // error: {
      //   name: "",
      //   description: "",
      //   start_time: "",
      //   end_time: "",
      //   status_id: "",
      //   image_header: "",
      //   image_thumbail: "",
      // },
      options: [
        { value: 0, text: "Laki-laki" },
        { value: 1, text: "Perempuan" },
      ],
      legality: [
        { id: null, label: "Pilih Badan Usaha", isDisabled: true },
        { id: 0, label: "PT" },
        { id: 1, label: "CV" },
        { id: 2, label: "Firma" },
        { id: 3, label: "PO" },
      ],

      // options
      roles: [],
      industryType: [],
      businessLegality: [],

      formData: new FormData(),
      monthly_omset: 0,
      photo_url: "/img/default/classroom_t.png",
      company_logo_url: "/img/default/classroom_h.png",
      default_photo_url: "/img/default/classroom_h.png",
      default_company_logo_url: "/img/default/classroom_t.png",
      product_photo: "/img/default/classroom_t.png",
      default_product_photo: "/img/default/classroom_t.png",
    };
  },
  methods: {
    async get() {
      this.form = await module.get(
        "api/members-token",
        `?token=${this.$route.query.env_pwd}`
      );

      this.form["_method"] = "PUT";
      this.form["password"] = "";
      this.form["token"] = this.$route.query.env_pwd;
      this.form["pitching_event_id"] = "";

      if (this.form.member_products.length > 0) {
        this.productCollection = this.form.member_products;
        this.productCollection.splice(this.productCollection.length);
        console.log("product", this.productCollection);
      }

      this.obj = {
        attachmentData: this.form.photo_url,
        fileName: this.form.company_logo_url,
      };
      console.log(this.form);
      // this.$root.$emit('attachmentSelected', obj)
      this.dataLoaded = true;
      // }
    },

    // async formOnsubmit() {
    //   // Make Request
    //   let response = await module.submit(
    //     this.form,
    //     "api/attendance-confirmation"
    //   );
    //   // Check Response
    //   if (response.state == "error") {
    //     // Validation Error
    //     this.error = validation.setValidationError(
    //       this.error,
    //       response.error.errors
    //     );
    //   } else {
    //     // Success
    //     Swal.fire(response.success.title, response.success.message, "success");
    //     console.log(response);
    //     this.state = "success";
    //   }
    // },

    async formOnsubmit() {
      // if(this.password_confirmation)

      if (this.form.member_role_id == 9 || this.form.member_role_id == 6) {
        this.form.member_role_id = 6;
        this.form.industry_type = 0;
        this.form.is_partner = 0;
        this.form.gender = 0;
        this.form.monthly_omset = 0;
        //
        this.form.business_name = this.form.name;
        this.form.business_address = this.form.name;
        this.form.business_city = this.form.name;
        this.form.postal_code = "0000";
        this.form.business_estabilished_age = this.form.name;
        this.form.description = this.form.name;
        this.form.business_legal_type = 0;
      }

      this.form.products = JSON.stringify(this.productCollection);

      console.log(this.form);
      // append form to formData
      this.formData.append("photo_url", this.photo_url);
      this.formData.append("company_logo_url", this.photo_url);
      this.formData.append(
        "productDeleted",
        JSON.stringify(this.productDeleted)
      );

      for (const [key, value] of Object.entries(this.form)) {
        this.formData.append(key, value);
      }

      // Make Request
      // this.form.photo_url = this.photo_url
      // this.form.company_logo_url = this.company_logo_url

      let response = await module.submit(
        this.formData,
        "api/attendance-confirmation"
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        // Swal.fire({
        //   title: response.success.title,
        //   text: response.success.message,
        //   icon: "success",
        //   toast: true,
        //   position: "top-end",
        //   showConfirmButton: false,
        //   timer: 3000,
        //   timerProgressBar: true,
        // });
        Swal.fire(response.success.title, response.success.message, "success");
        // this.$router.push("/members");
        this.state = "success";
      }
    },

    onPhoto(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        console.log("filesssss", e.target.files);
        this.form.photo_url = e.target.files[0];

        reader.onload = (event) => {
          this.photo_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onLogo(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        console.log("filesssss 2", e.target.files);
        this.form.company_logo_url = e.target.files[0];

        reader.onload = (event) => {
          this.company_logo_url = event.target.result;
        };
        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onUploadProductPhoto(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        console.log("files", e.target.files);
        this.formProduct.image = e.target.files[0];
        this.fileCollection.push(e.target.files[0]);

        reader.onload = (event) => {
          this.product_photo = event.target.result;
          this.formProduct.image_display = event.target.result;
        };
        reader.readAsDataURL(file);

        if (this.productPurpose == "add") {
          this.formData.append(
            `productImage${this.productCollection.length}`,
            this.formProduct.image
          );
        } else {
          this.formData.append(
            `productImage${this.indexProduct}`,
            this.formProduct.image
          );
        }
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    onRemovePhoto() {
      this.photo_url = null;
      this.form.photo_url = null;
    },
    onRemoveLogo() {
      this.company_logo_url = null;
      this.form.company_logo_url = null;
    },
    onRemoveProductPhoto() {
      this.product_photo = null;
      this.formProduct.image = null;
    },

    validationMessage() {
      Swal.fire({
        title: "Gagal",
        text: "Harap periksa kembali form",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
    },

    async statusOptions() {
      let response = await module.setTreeSelect(
        "api/no-auth-member-roles",
        "?id_selected=834"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.roles = response.data;
        this.roles.unshift({
          label: "Pilih Peran",
          id: "",
          isDisabled: true,
        });
      }
    },

    async businessLegalityFun() {
      console.log("run");
      let response = await module.setTreeSelect(
        "api/no-auth-business-legalities"
      );
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.businessLegality = response.data;
        this.businessLegality.unshift({
          label: "Pilih Badan Usaha",
          id: "",
          isDisabled: true,
        });
      }
    },

    async industryTypeFun() {
      let response = await module.setTreeSelect("api/no-auth-industry-types");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.industryType = response.data;
        this.industryType.unshift({
          label: "Pilih Industri",
          id: "",
          isDisabled: true,
        });
      }
    },
    resetFormProduct() {
      this.formProduct.price = "";
      this.formProduct.name = "";
      this.formProduct.description = "";
      this.formProduct.image = "";
      this.productPurpose = "add"
    },
    saveProduct() {
      if (this.formProduct.image_display == "") {
        this.formProduct.image_display = this.default_product_photo;
        this.fileCollection.push(this.formProduct.image_display);
        if (this.productPurpose == "add") {
          this.formData.append(
            `productImage${this.productCollection.length}`,
            this.formProduct.image_display
          );
        } else {
          this.formData.append(
            `productImage${this.indexProduct}`,
            this.formProduct.image_display
          );
        }
      }

      if (this.productPurpose == "edit") {
        this.productCollection[this.indexProduct].name = this.formProduct.name;
        this.productCollection[this.indexProduct].price =
          this.formProduct.price;
          this.productCollection[this.indexProduct].description = this.formProduct.description;
      } else {
        let clone = { ...this.formProduct };
        this.productCollection.push(clone);
      }

      this.resetFormProduct();
      console.log("product", this.productCollection);
      this.$bvModal.hide("modal-form-product");
      this.productPurpose = "add"
    },
    deleteProduct(index) {
      if (this.productCollection[index].hasOwnProperty("id")) {
        this.productDeleted.push(this.productCollection[index].id);
        console.log("productDeleted", this.productDeleted);
      }
      this.productCollection.splice(index, 1);
      this.fileCollection.splice(index, 1);
      for (let a = 0; a < this.fileCollection.length; a++) {
        this.formData.append(
          `productImage${this.productCollection.length - 1}`
        );
      }
    },
    getProductByIndex(index) {
      this.indexProduct = index;
      this.productPurpose = "edit";
      this.formProduct.name = this.productCollection[index].name;
      this.formProduct.description = this.productCollection[index].description;
      this.formProduct.price = this.productCollection[index].price;
      this.formProduct.image = this.productCollection[index].image_display;
      this.default_product_photo = this.productCollection[index].image_display;
      this.product_photo = this.productCollection[index].image_display;
      this.$bvModal.show("modal-form-product");
    },
  },
  mounted() {
    this.get();
    this.industryTypeFun();
    this.statusOptions();
    this.businessLegalityFun();
    this.form.token = this.$route.query.env_pwd;
    this.form.pitching_event_id = this.$route.query.event;
    console.log("form", this.form);
    console.log("route", this.$route);
  },
  watch: {
    form: function (newVal, oldVal) {
      console.log("form", this.form);
      if (this.purpose == "edit") {
        this.default_photo_url = this.form.photo_url;
        this.photo_url = this.form.photo_url;
        this.default_company_logo_url = this.form.company_logo_url;

        this.productCollection = this.form.member_products;
        this.productCollection.splice(this.productCollection.length);
        console.log("product", this.productCollection);
      }
    },
  },
  computed: {
    backgroundImage() {
      // return process.env.BASE_URL + "media/error/bg1.jpg";
      //   return process.env.BASE_URL + "media/bg/sc-home1-bg.png";
      return "/img/confirmation/onp-bg-1.jpg";
    },
    photoCpt() {
      return this.photo_url == null ? this.default_photo_url : this.photo_url;
    },
    logoCpt() {
      return this.company_logo_url == null
        ? this.default_company_logo_url
        : this.company_logo_url;
    },
    productPhoto() {
      return this.product_photo == null
        ? this.default_product_photo
        : this.product_photo;
    },

    formatedMonthlyOmset: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.monthly_omset = newValue.toLocaleString("id-ID");
          this.form.monthly_omset = newValue;
        } else {
          this.monthly_omset = 0;
        }
      },
      get() {
        return this.form.monthly_omset.toLocaleString("id-ID");
      },
    },
    formatedProductPrice: {
      set(newValue) {
        newValue = parseInt(newValue.replace(/\D/g, ""), 10);
        if (!Number.isNaN(newValue)) {
          this.formProduct.price = newValue.toLocaleString("id-ID");
        } else {
          this.formProduct.price = "";
        }
      },
      get() {
        return this.formProduct.price;
      },
    },
  },
};
</script>
<style>
.background-red {
  background-color: #a21322;
  margin: 0px;
}
.text-title {
  font-size: 20px;
  color: #a21322;
  text-shadow: 3px 2px rgba(168, 168, 168, 0.589);
  font-family: "Poppins", sans-serif;
}
.text-confirmation-success {
  font-size: 20px;
  color: #a21322;
  font-family: "Poppins", sans-serif;
}
.text-content-title {
  font-size: 20px;
  font-weight: bold;
}
.text-content-desc {
  font-size: 20px;
  font-weight: bold;
}
.text-conf {
  font-size: 35px;
  color: white;
}
.back-trans {
  background-color: rgba(255, 255, 255, 0.623);
}
.btn-confirm {
  margin-bottom: -45px;
  color: #a21322;
  background-color: rgb(250, 192, 192);
  border-radius: 25px;
  /* border: 2px solid #a21322; */
}

.image-input-wrapper-thumbnail {
  width: 200px !important;
  height: 200px !important;
}

.image-input-wrapper-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-header {
  width: 280px !important;
  height: 120px !important;
}

.image-input-wrapper-header img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.image-input-wrapper-product {
  width: 50px !important;
  height: 50px !important;
}

.image-input-wrapper-product img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>